import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";

import api from "../../services/api";

import "react-phone-number-input/style.css";
import "./styles.css";

import {
  Container,
  ContainerHeader,
  TrainerInfo,
  TrainerPhoto,
  TrainerName,
  Content,
  Input,
  RadioGroupContainer,
  RadioLabel,
  RadioInput,
  BtnSend,
  ErrorsArea,
  Logo,
  TitleSuccess,
  SubTitleSuccess,
} from "./styles";

const logoImg = require("../../assets/images/logo.png");

const options = [
  { label: "Maculino", value: "masculino" },
  { label: "Feminino", value: "feminino" },
];

export default function SignUp() {
  const { trainerId } = useParams<Record<string, string | undefined>>();

  const [refreshing, setRefreshing] = useState(true);
  const [signUpFinished, setSignUpFinished] = useState(false);
  const [trainer, setTrainer] = useState<any>(null);
  const [inputName, setInputName] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [inputBirthDate, setInputBirthDate] = useState("");
  const [inputPhoneNumber, setInputPhoneNumber] = useState("");
  const [inputGender, setInputGender] = useState("masculino");
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState<any>([]);

  const loadData = useCallback(async () => {
    try {
      if (trainerId) {
        const response = await api.get(`/trainer/${trainerId}/signup`);

        if (response) {
          if (response?.data?.success) {
            //console.log(response?.data.value);
            setTrainer(response?.data?.value);

            setRefreshing(false);
          } else {
            alert("Ocorreu um erro inesperado!");
          }
        } else {
          alert("Ocorreu um erro inesperado!");
        }
      } else {
        alert("Verfifique a url!");
      }
    } catch (err) {
      alert("Ocorreu um erro inesperado!");
    }
  }, [trainerId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const formatDate = (dateString: string): string => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  async function handleSubmit(e: any) {
    e.preventDefault();
    setLoading(true);
    setErrorMessages([]);
    let errors = [];

    if (inputName.length < 3) {
      errors.push("Insira um nome válido");
    }
    if (inputEmail.length < 3) {
      errors.push("Insira um email válido");
    }
    if (inputPhoneNumber.length < 5 || !isValidPhoneNumber(inputPhoneNumber)) {
      errors.push("Insira um telefone válido");
    }
    if (inputBirthDate.length < 3) {
      errors.push("Insira uma data de nascimento válida");
    }

    if (errors.length > 0) {
      setErrorMessages(errors);
      setLoading(false);

      return;
    }

    try {
      const payload = {
        name: inputName,
        email: inputEmail,
        phone_number: inputPhoneNumber,
        birth_date: formatDate(inputBirthDate),
        gender: inputGender,
        trainer_id: trainerId,
      };
      //console.log(payload);

      const response = await api.post(`/contact/register`, payload);
      if (response) {
        if (response?.data?.success) {
          //console.log(response?.data.value);
          setSignUpFinished(true);
        } else if (response.data.message) {
          setErrorMessages([response.data.message]);
        } else {
          alert("Ocorreu um erro inesperado!");
        }
      } else {
        alert("Ocorreu um erro inesperado!");
      }
    } catch (err) {
      //console.log(err);
      alert("Ocorreu um erro inesperado!");
    }

    setLoading(false);
    return false;
  }
  return (
    <Container>
      <ContainerHeader />
      {!refreshing && trainer ? (
        <>
          <TrainerInfo>
            <TrainerPhoto src={trainer?.photo} />
            {/* <TrainerPhoto src={logoImg} /> */}
            <TrainerName>{trainer?.name}</TrainerName>
          </TrainerInfo>
          {!signUpFinished ? (
            <Content>
              <h5>Cadastrar</h5>
              <Input
                type="text"
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
                placeholder="Nome"
              />
              <Input
                type="email"
                value={inputEmail}
                onChange={(e) => setInputEmail(e.target.value)}
                placeholder="Email"
              />
              <PhoneInput
                defaultCountry="BR"
                value={inputPhoneNumber}
                rules={{ required: true }}
                placeholder="Telefone"
                onChange={(e: any) => setInputPhoneNumber(e)}
                className="input-phone-number"
              />
              <Input
                type="date"
                value={inputBirthDate}
                onChange={(e) => setInputBirthDate(e.target.value)}
                placeholder="Data de nascimento"
                style={{
                  display: "flex",
                  flex: 1,
                }}
              />
              <RadioGroupContainer>
                {options.map((option) => (
                  <RadioLabel key={option.value}>
                    <RadioInput
                      type="radio"
                      name={inputGender}
                      value={option.value}
                      checked={inputGender === option.value}
                      onChange={(e) => setInputGender(e.target.value)}
                    />
                    {option.label}
                  </RadioLabel>
                ))}
              </RadioGroupContainer>
              {errorMessages && errorMessages.length > 0 && (
                <ErrorsArea>
                  {errorMessages.map((item: any, ind: number) => (
                    <span key={ind}>{item}</span>
                  ))}
                </ErrorsArea>
              )}
              <BtnSend disabled={loading} onClick={handleSubmit}>
                {loading ? "CARREGANDO..." : "CADASTRAR"}
              </BtnSend>
            </Content>
          ) : (
            <>
              <Content>
                <TitleSuccess>Sucesso!!!</TitleSuccess>
                <SubTitleSuccess>
                  Logo entraremos em contato com você!
                </SubTitleSuccess>
              </Content>
            </>
          )}
        </>
      ) : (
        <span>Carregando...</span>
      )}
      {/* <Logo src={require("../../assets/images/logo-name.png")} /> */}
    </Container>
  );
}
