import React from "react";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div>
      <span>Em construção!</span>
      <br />
      {/* <Link to={"/signup"}>cadastrar</Link> */}
    </div>
  );
}
