import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex: 1;
    flex-direction: column;
    align-items: center;
    background-color: #ebebeb;
    padding: 20px;
    overflow-y: auto;
`;

export const ContainerHeader = styled.div`
    width: 100%;
    height: 200px;
    background: linear-gradient(to right, #161616, #ED2A44);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 400px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    z-index: 2;
    box-shadow: 0 0 2px #999;
`;

export const TrainerInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    max-width: 400px;
    padding: 20px;
    border-radius: 10px;
    z-index: 2;
`;

export const TrainerPhoto = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 40px;
    border: 2px solid #ED2A44;
    background-color: #ccc;
`;

export const TrainerName = styled.span`
    display: flex;
    flex: 1;
    font-size: 18px;
    color: #fff;
    margin-left: 10px;
    text-shadow: 0 0 5px #161616;
`;

export const Input = styled.input`
    display: flex;
    flex: 1;
    padding: 15px 10px;
    margin-top: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
`;

export const RadioGroupContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
`;

export const RadioLabel = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin: 0 5px;
`;

export const RadioInput = styled.input`
    width: 20px;
    height: 20px;
    margin-top: 0;
    margin-right: 8px;
`;

export const ErrorsArea = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    color: #f00;
    font-size: 14px;
    margin-top: 20px;
`;

export const BtnSend = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
    border-radius: 4px;
    border: 0px;
    background-color: #ED2A44;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    margin-top: 15px;
    cursor: pointer;
`;

export const Logo = styled.img`
    width: 150px;
    height: 80px;
    object-fit: contain;
`;

export const TitleSuccess = styled.span`
    font-size: 18px;
    font-weight: bold;
    color: #ED2A44;
    text-align: center;
    align-self: center;
    margin-top: 20px;
`;

export const SubTitleSuccess = styled.span`
    font-size: 16px;
    color: #161616;
    text-align: center;
    align-self: center;
    margin-top: 10px;
    margin-bottom: 20px;
`;